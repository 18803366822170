// 
// profile.scss
//

.profile-pills {
    li{
      a{
        color: rgba($dark,0.5) !important;
        padding: 0 10px;
        display: block;
        line-height: 30px !important;
  
        i {
          font-size: 14px;
        }
      }
    }
}

.comment-footer {
    display: block;
    font-size: 12px;;

    i {
      font-size: 13px;
      margin-right: 7px;
    }
  }
  .comment-footer, .comment-footer a {
    color: rgba($dark, 0.5);

    &:hover {
      color: $primary;
    }
}