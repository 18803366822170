// 
// pricing.scss
//

.pricing-column{
    position: relative;
    margin-bottom: 40px;
  
    .inner-box {
      position: relative;
      padding: 0px 0px 40px;
    }

    .plan-title {
      font-size: 16px;
      color: $primary;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
    }
    .plan-price {
      font-size: 40px;
      margin-bottom: 10px;
      color: $dark;
    }
    .plan-duration {
      font-size: 13px;
      color: $gray-600;
    }
  
    .plan-stats {
  
      li {
        margin-bottom: 15px;
        line-height: 24px;
      }
    }
  }
  
  .ribbon {
    position: absolute;
    left: 5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
  
    span {
      font-size: 10px;
      color: $white;
      text-transform: uppercase;
      text-align: center;
      line-height: 20px;
      transform: rotate(-45deg);
      width: 100px;
      display: block;
      box-shadow: 0 0px 8px 0 rgba($dark, 0.06), 0 1px 0px 0 rgba($dark, 0.02);
      background: $primary;
      position: absolute;
      top: 19px;
      left: -21px;
  
      &:before {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid $primary;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid $primary;
      }
      &:after {
        content: "";
        position: absolute;
        right: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid $primary;
        border-bottom: 3px solid transparent;
        border-top: 3px solid $primary;
      }
    }
  }