.userInputEmails {
  background-color: #ffffff;
  border: 1px solid lightgrey;
  border-radius: 5px;
  height: 100px;
  padding: 10px;
}
.userInputEmails input {
  border: none;
  width: 340px;
}
.userInputEmails input:focus-visible {
  outline: none;
}
.shareAssessmentForm .userInputEmails {
  border: none;
  height: 140px;
  width: 350px;
  font-family: Roboto;
  font-size: 12px;
  padding: 6px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.tag-item {
  background-color: #ededee;
  color: #3c3e4d;
  display: inline-block;
  font-size: 12px;
  border-radius: 30px;
  height: 25px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  width: 22px;
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  line-height: inherit;
  color: #3c3e4d;
  -webkit-appearance: button;
  font-size: 22px;
  opacity: 0.7;
}
