//
// formm-wizard.scss
//


#commentForm{
    .error{
        color: $danger;
        font-size: 12px;
        font-weight: $font-weight-medium;
    }
}