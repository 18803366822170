.publish {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin: 15px;
    background-color: #71b6f9 !important;
    border: none !important;
}

.unpublish {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin: 15px;
    color: #71b6f9 !important;
    background-color: white !important;
    border: #71b6f9 solid 2px !important;
}