//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
}
.skillSethld {
  cursor: text;
  display: flex;
  flex-flow: row wrap;
  min-height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
  padding: 1rem 0.75rem 0.6rem 0.75rem;
  input {
    border: none;
    &:focus-visible {
      outline: none;
    }
  }
}
.skillDropdown {
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  z-index: 2;
  overflow: auto;
  height: 150px;
  width: 100%;
  p {
    padding: 8px 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
  }
}
