//
// events.scss
//
.event-cardHld {
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  padding: 16px;
}
.event-overview {
  .event-link-generated {
    background: #fff0f0;
    padding: 10px;
    border-radius: 4px;
    font-size: 13px;
    color: #808080;
    width: 40%;
    overflow-x: hidden;
    white-space: nowrap;
  }
  p {
    font-size: 14px;
    color: #353535;
  }
  .inputInfo {
    font-size: 12px;
    color: #808080;
    line-height: 20px;
  }
  .SelectedAttendeeType {
    color: #f44d5e;
    border-bottom: 3px solid #f44d5e;
    padding-bottom: 10px;
    font-weight: 500;
  }
  .eventCurvedBorder {
    border-radius: 4px;
  }
}
.event-primary-btn {
  border: 1px solid #f44d5e;
  background-color: #f44d5e;
  border-radius: 4px;
  color: #ffffff;
  padding: 4px 14px;
}
.event-secondary-btn {
  color: #f44d5e;
  padding: 8px 20px;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
}
.event-primary-btn:disabled {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  cursor: no-drop;
}
.event-outlined-btn {
  border: 1px solid #f44d5e;
  border-radius: 4px;
  color: #f44d5e;
  background: #ffffff;
  padding: 4px 14px;
}
.event-outlined-btn:disabled {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  cursor: no-drop;
}
.brand-color {
  color: #f44d5e;
}
.lightGrey-color {
  color: rgba(0, 0, 0, 0.26);
}
.recent-logins {
  img {
    width: 50px;
    height: 50px;
  }
  p,
  .designation {
    display: -webkit-box;
    overflow: hidden;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-weight: 400;
  }
}
.sessionDayTab {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: #fbfbfb;
  display: inline-block;
  width: fit-content;
  cursor: pointer;
  p {
    padding: 10px;
  }
  .selected {
    background-color: #f44d5e;
    color: #ffffff;
  }
}
.sessionHld {
  .time {
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90px;
  }
  .speakershld {
    display: flex;
    div {
      width: 50px;
      height: 50px;
      img {
        border-radius: 50px;
        border: 1px solid #ffffff;
      }
    }
    img {
      border-radius: 50px;
      border: 1px solid #ffffff;
      width: 30px;
      height: 30px;
    }
  }
  .meetIcon {
    font-size: 20px;
  }
  span {
    font-size: 12px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.trackhld {
  .colorIndicator {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }
  .iconsHld {
    display: none;
  }
}
.trackhld:hover {
  .iconsHld {
    display: block;
  }
}
.createtrackhld {
  background: #f6f6f6;
  padding: 16px;
  border-radius: 10px;
  input {
    border: none;
    border-radius: 10px;
    width: 85%;
    background: #f6f6f6;
    height: 38px;
    padding: 10px;
  }
  span {
    font-size: 20px;
  }
}
.createtrackhld input:focus-visible {
  outline: 1px auto #f44d5e;
  box-shadow: #f44d5e;
}
.event-label {
  font-size: 14px;
  color: #808080;
  font-weight: 400;
}

.addSession .MuiTypography-h5 {
  font-size: 1rem;
}
.addSession .MuiDropzoneArea-icon {
  width: 50px;
  height: 50px;
}
.addSession .MuiDropzoneArea-root {
  min-height: 145px;
}
.sessionDetails {
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;
}
.addSpeakers {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgba(237, 119, 103, 0.04);
  color: #f44d5e;
  display: table;
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 20px;
  cursor: pointer;
}
.eventOptionHld {
  position: absolute;
  right: 10px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  top: 29px;
  p {
    padding: 10px;
  }
}
.eventOptionHld p:hover {
  background-color: #dbdbdb;
}
.socialmediabtn {
  cursor: pointer;
}
.speakerImg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
}
.tableCellConTent {
  background-color: #dfd4f7;
  color: #604c8c !important;
  line-height: 20px;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 10px;
}
.BrandinglogoImgHld {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.ImgHld {
  position: relative;
  margin-bottom: 20px;
  .Imagesoverlay {
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    cursor: pointer;
  }
}

.ImgHld:hover {
  .Imagesoverlay {
    visibility: visible;
  }
}
.agendaTitle {
  width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.virtualBoothSize {
  background-color: #f6f6f6;
  border-radius: 5px;
  cursor: pointer;
}

.virtualBoothSize p {
  color: #dbdbdb;
  margin-bottom: 0px;
}

.virtualBoothSizeSection {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2.5;
  color: #353535;
}

.virtualBoothAddBannerButton {
  border: transparent;
  width: 100px;
  height: 37px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  background-color: #f44d5e;
}

.virtualBoothAddBannerButtonText p {
  margin-bottom: 0px;
  color: #808080;
}

.virtualBoothAddress {
  height: 100px;
}

.virtualBoothCheckBox {
  margin-right: 10px;
}
.eventsBreadcrumb li {
  list-style: none;
  display: inline;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  color: #a5a5a5;
}
.eventsBreadcrumb li:after {
  content: "\203A";
  padding: 0;
  margin: 0 16px;
  vertical-align: middle;
  font-size: x-large;
  line-height: 0;
  color: #a5a5a5;
}
.eventsBreadcrumb li:last-child:after {
  content: none;
}
.peopleGroup {
  .count {
    font-size: 14px;
    background: #f6f6f6;
    color: #353535;
    padding: 6px 10px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-right: 2px;
  }
  .groupName {
    color: #604c8c;
    background-color: #dfd4f7;
    font-size: 10px;
    padding: 8px;
    border-radius: 4px;
    margin-right: 5px;
  }
}
.customSelectControls {
  border: 1px solid #c0c0c0;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 7.2px 12px;
  cursor: pointer;
  span {
    color: rgb(165, 165, 165);
  }
}
.customDropDown {
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: absolute;
  width: 100%;
  top: 50px;
  z-index: 2;
  padding-top: 14px;
  // box-shadow: 20px 20px 20px 0 rgba(196, 196, 196, 0.2);
  p {
    text-align: left;
    padding: 10px;
    cursor: pointer;
  }
  .optionsHld {
    height: 150px;
    overflow: auto;
  }
  .createCategoryHld {
    position: relative;
    .form-control {
      background: #f6f6f6;
      border-color: #f6f6f6;
    }
    .createcategoryinputOptions {
      position: absolute;
      right: 17px;
    }
  }
}
.peopleDropdown {
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  max-height: 250px;
  overflow: auto;
  position: absolute;
  width: 100%;
  bottom: -50px;
}
// .customDropDown:hover {
//   p{
//     background-color: rgba(0, 0, 0, 0.04);
//   }
// }
